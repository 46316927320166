;(function($){

    function Options()
    {

        $(window).on('load',function(){
            value = $('#type-extrafield').val() == 'select' ? 'block':'none';
            $(".box-option").css('display', value );
        });

        $(document).on("change","#type-extrafield", function(){
        	var value = $(this).val() == 'select' ? 'block':'none';
           
            $(".box-option").css('display', value );
        });

        $(document).on("click","#btn-add-option", function(event){

        	event.preventDefault();

        	var value = $("#in-option").val();

            var id_item = Math.random().toString(36).substr(2, 9);

        	var last_tr ='<tr>'+
							'<td data-label="Opção">'+
								'<div class="form-row">'+
									'<input type="text" name="option['+id_item+'][value]" class="form-control" placeholder="digite a opção" value="'+value+'">'+
								'</div>'+
							'</td>'+
							'<td data-label="Ação" style="text-align: center;">'+
								'<a href="" class="btn-primary btn-delete-option">'+
									'<i class="fa fa-trash-o" aria-hidden="true"></i>'+
								'</a>'+
							'</td>'+
						'</tr>'; 

        	
        	$('#listOptions tr:last').before(last_tr);
            $('#in-option').val("");
        });

        $(document).on("click",".btn-delete-option", function(event){
            event.preventDefault();
            
            $(this).parent().parent().remove();
        });
    }

   	new Options();

}(jQuery));
 
 
 
 
