;(function($){

    function Results() {

        var _ =  this;

        $("select#results_events_id").on("change", function(e){
            e.preventDefault();
            
            if ($(this).val() == "")
            {
                return false;
            }

            _.getModalities($(this).val());
        });

        $("select#results_modalities_id").on("change", function(e){
            e.preventDefault();
            
            if ($(this).val() == "")
            {
                return false;
            }

            _.getCategories($(this).val());
        });

        _.getModalities($("select#results_events_id").val());
    }

    Results.prototype.getModalities =  function(results_events_id) {

        var _ = this;

        var html = '<option value="">Selecione</option>';

        $.get($("#app_url").val() + '/api/resultados/modalidades/' + results_events_id, function(data) {

            $.each(data, function(index, value) {
                var selectedStatus = ($("select#results_modalities_id").data('active') == value.results_modalities_id) ? "selected" : "";
                html += '<option value="'+ value.results_modalities_id+'" '+ selectedStatus +'>'+ value.title +'</option>';
            });

        }).done(function(){
            $("select#results_modalities_id").html(html).attr('disabled', false);
            _.getCategories($("select#results_modalities_id").val());
        });       
    };

    Results.prototype.getCategories =  function(results_modalities_id) {

        var html = '<option value="">Selecione</option>';

        $.get($("#app_url").val() + '/api/resultados/categorias/' + results_modalities_id, function(data) {

            $.each(data, function(index, value) {
                var selectedStatus = ($("select#results_categories_id").data('active') == value.results_categories_id) ? "selected" : "";
                html += '<option value="'+ value.results_categories_id+'" '+ selectedStatus +'>'+ value.title +'</option>';
            });

        }).done(function(){
            $("select#results_categories_id").html(html).attr('disabled', false);
        });       
    };

   	new Results();

}(jQuery));