;(function($){

    function Organizers() {
   	 	$("#type_organizer").on("change", function(e){
            e.preventDefault();
            $(".person").addClass('hidden');       
            $(".person input").attr('disabled', true);
            $(".person-"+ $(this).val()).removeClass('hidden');
            $(".person-"+ $(this).val()+ " input").attr('disabled', false);
        });

        if($("#type_organizer").val() != "")
        {
            $(".person").addClass('hidden');
            $(".person input").attr('disabled', true);
            $(".person-"+ $("#type_organizer").val()).removeClass('hidden');
            $(".person-"+ $("#type_organizer").val() + " input").attr('disabled', false);
        }
    }

   	new Organizers();

}(jQuery));