;(function($){

    function Events() {
   	    $(".modalities input[type='checkbox']").change(function(e){
            e.preventDefault();

            if($(this).is(':checked')) {
               $(".price_modalities-"+ $(this).val()).attr('disabled', false);
            }else {
                 $(".price_modalities-"+ $(this).val()).attr('disabled', true);
            }
        });

        $(".modalities input[type='checkbox']").each(function( index, value ) {
            if($(value).is(':checked')) {
               $(".price_modalities-"+ $(this).val()).attr('disabled', false);
            }else {
                $(".price_modalities-"+ $(this).val()).attr('disabled', true);
            }
        });
    }

   	new Events();

}(jQuery));